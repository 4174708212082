import { Navigate } from "react-router-dom";

// auth
import { useAuth } from "../contexts/AuthContext";

const AdminRoute = ({ children }: any) => {
  const { currentUser, loading } = useAuth();
  return !currentUser && !loading ? (
    <Navigate to="/signup" replace />
  ) : (
    currentUser &&
      currentUser.uid === "gFMGlYHTSxZCy2qnSH54Wyu1EDH2" &&
      children
  );
};

export default AdminRoute;
