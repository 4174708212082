import { useEffect, useState } from "react";
import "./AdminPage.css";
import { FaUserAlt, FaHammer } from "react-icons/fa";
import { BiTime } from "react-icons/bi";
import { BsCloudCheck, BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineFlag } from "react-icons/ai";

// components
import Skeleton from "../../components/Skeleton/Skeleton";

// firebase services
import { getAllDocs } from "../../services/firebase";
import { Timestamp } from "firebase/firestore";

// interface
interface ReceivedImages {
  id: string;
  name: string;
  type: string;
  url: string;
  createdBy: string;
  createdAt: Timestamp;
}

const AdminPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    getAllDocs("receivedImages")
      .then((res: any) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      });
  }, []);

  if (error) {
    return <h2>data fetch error...</h2>;
  }

  if (isLoading) {
    return (
      <section>
        <h2 className="text-center my-1">Admin dashboard</h2>
        <div className="container">
          {[1, 2, 3].map((e) => (
            <div key={e} className="admin_card p-1">
              <Skeleton type="" marginTop="" width="300px" height="100%" />

              <div className="card_details">
                <div>
                  <Skeleton
                    type=""
                    width="100%"
                    height="20px"
                    marginTop="1rem"
                  />
                  <Skeleton
                    type=""
                    width="100%"
                    height="20px"
                    marginTop="1rem"
                  />
                  <Skeleton
                    type=""
                    width="100%"
                    height="20px"
                    marginTop="1rem"
                  />
                </div>

                <div className="admin_curation_status">
                  <Skeleton
                    type="circle"
                    width="30px"
                    height="30px"
                    marginTop="1rem"
                  />
                  <Skeleton
                    type="circle"
                    width="30px"
                    height="30px"
                    marginTop="1rem"
                  />
                  <Skeleton
                    type="circle"
                    width="30px"
                    height="30px"
                    marginTop="1rem"
                  />
                  <Skeleton
                    type="circle"
                    width="30px"
                    height="30px"
                    marginTop="1rem"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }

  return (
    <main>
      <section>
        <h2 className="text-center my-1">Admin dashboard</h2>

        <div className="container p-1">
          {data.length > 0 &&
            data.map((doc: ReceivedImages) => (
              <div key={doc.id} className="admin_card">
                <div className="admin_card_img">
                  <img src={doc.url} alt={doc.id} />
                </div>

                <div className="card_details">
                  <div>
                    <h3 className="my-1">{doc.name.substring(0, 20)}</h3>
                    <p className="d-flex align-item-center gap-10">
                      <FaUserAlt size={"24px"} /> {doc.createdBy}
                    </p>
                    <p className="d-flex align-item-center gap-10 my-1">
                      <BiTime size={"24px"} />{" "}
                      {doc.createdAt?.toDate().toString()}
                    </p>
                  </div>

                  <div className="admin_curation_status color-gray">
                    <BsCloudCheck size={"25px"} className="color-s" />
                    <AiOutlineFlag size={"25px"} className="color-s" />
                    <FaHammer size={"25px"} />
                    <BsFillCheckCircleFill size={"25px"} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </main>
  );
};

export default AdminPage;
