import { Link } from "react-router-dom";
import "./ErrorPage.css";

const ErrorPage = () => {
  return (
    <div className="text-center my-1">
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>
        Go to <Link to="/">home</Link>
      </p>
    </div>
  );
};

export default ErrorPage;
