import { FC } from "react";
import "./Avatar.css";
import { Link } from "react-router-dom";

// context
import { useAuth } from "../../contexts/AuthContext";

// components
import Skeleton from "../Skeleton/Skeleton";

const Avatar: FC<{ type?: string }> = ({ type }) => {
  const { currentUser, loading } = useAuth();

  const classes = `avatar ${type}`;

  return (
    <div className={classes}>
      {currentUser ? (
        <Link to={"/profile"}>
          <img
            src={`${process.env.REACT_APP_AVATAR_URL}/micah/${currentUser.uid}.svg`}
            alt="avatar"
          />
        </Link>
      ) : (
        loading && (
          <Skeleton type="circle" width="40px" height="40px" marginTop="0" />
        )
      )}
    </div>
  );
};

export default Avatar;
