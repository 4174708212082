import { FC, useEffect, Dispatch } from "react";
import "./HomePage.css";

// components
import UploadImageModal from "../../components/UploadImageModal/UploadImageModal";
import AuthModal from "../../components/AuthModal/AuthModal";

// svg
import logoXl from "../../assets/svg/logo/logo_xl.svg";
import clinicianSvg from "../../assets/svg/verticals/Clinician.svg";
import deviceMakerSvg from "../../assets/svg/verticals/DeviceMaker.svg";
import familyMembersSvg from "../../assets/svg/verticals/FamilyMembers.svg";
import pregnantMotherSvg from "../../assets/svg/verticals/PregnantMother.svg";

// context
import { useAuth } from "../../contexts/AuthContext";

const HomePage: FC<{ isOpen: boolean; setIsOpen: Dispatch<boolean> }> = ({
  isOpen,
  setIsOpen,
}) => {
  // ESC function
  // useEffect hook
  useEffect(() => {
    const escFunction = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [setIsOpen]);

  // auth context
  const { currentUser } = useAuth();

  return (
    <>
      <header className="text-center">
        <div>
          <img src={logoXl} alt="baby svg" />
        </div>
        <div>
          <h1 className="header-title">Unfolding The Baby Mystery</h1>
        </div>

        <div className="mt-2">
          <button className="btn" onClick={() => setIsOpen(true)}>
            Get Started <span className="bg-warning">Beta</span>
          </button>
        </div>
      </header>

      <main>
        {/* image upload modal section */}
        <section>
          {isOpen && (
            <>
              <UploadImageModal setIsOpen={setIsOpen} />
              {!currentUser && <AuthModal setIsOpen={setIsOpen} />}
            </>
          )}
        </section>

        <section className="container">
          <h3 className="text-center mt-2">Service Segments</h3>

          <div className="features">
            <div className="card">
              <img src={pregnantMotherSvg} alt="ultra baby" />

              <p>Pregnant Mother</p>
            </div>

            <div className="card">
              <img src={familyMembersSvg} alt="ultra baby" />

              <p>Family and Friend</p>
            </div>

            <div className="card">
              <img src={clinicianSvg} alt="ultra baby" />

              <p>Clinician</p>
            </div>

            <div className="card">
              <img src={deviceMakerSvg} alt="ultra baby" />

              <p>Device Maker</p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;
