import { FC } from "react";
import "./GoogleAuthBtn.css";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";

// auth context
import { useAuth } from "../../contexts/AuthContext";
import { UserCredential } from "firebase/auth";

// utils
import { toggleToastMsg } from "../../utils/validation";

const GoogleAuthBtn: FC<{ text: string }> = ({ text }) => {
  const { signInWithGoogle } = useAuth();

  const navigate = useNavigate();

  const clickHandler = async () => {
    try {
      const res: UserCredential = await signInWithGoogle();

      if (res.user) {
        navigate("/");
        toggleToastMsg("success", "Successfully logged in");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="my-1">
      <button className="btn w-100 flex-center gap-10" onClick={clickHandler}>
        <FcGoogle size={"20px"} /> {text} with Google
      </button>
      <h3 className="my-1 text-center">Or</h3>
    </div>
  );
};

export default GoogleAuthBtn;
