import { FC, useEffect, useState } from "react";
import "./UserActivities.css";
import { useNavigate, useLocation } from "react-router-dom";
import { BsCloudCheck, BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { FaBroom, FaCheck, FaFrown } from "react-icons/fa";

// components
import Skeleton from "../../components/Skeleton/Skeleton";

// auth context
import { useAuth } from "../../contexts/AuthContext";

import { getDataByUID } from "../../services/firebase";

// interface
interface ReceivedImages {
  id: string;
  name: string;
  type: string;
  url: string;
  createdBy: string;
  createdAt: any;
}

const UserActivities: FC = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const abortController = new AbortController();

    if (currentUser) {
      getDataByUID("receivedImages", currentUser.uid)
        .then((res: any) => {
          setIsLoading(false);
          setData(res);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    }

    return () => abortController.abort();
  }, [currentUser]);

  // on click handler
  const clickHandler = (docId: string) => {
    navigate(`${pathname}/activity-details/${docId}`);
  };

  if (error) {
    return <h2>fetch error..</h2>;
  }

  if (isLoading) {
    return (
      <div className="activities_cards">
        {[1, 2, 3, 4].map((e) => (
          <div key={e} className="activities_card my-1">
            <Skeleton type="" width="100%" height="200px" marginTop="10px" />
            <Skeleton type="" width="100%" height="10px" marginTop="10px" />
            <Skeleton type="" width="100px" height="10px" marginTop="10px" />
            <div className="curation_status">
              <Skeleton
                type="circle"
                width="20px"
                height="20px"
                marginTop="10px"
              />
              <Skeleton
                type="circle"
                width="20px"
                height="20px"
                marginTop="10px"
              />
              <Skeleton
                type="circle"
                width="20px"
                height="20px"
                marginTop="10px"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="activities_cards">
      {data && data.length > 0 ? (
        data.map((doc: ReceivedImages) => (
          <div
            key={doc.id}
            className="activities_card my-1"
            onClick={() => clickHandler(doc.id)}
          >
            <img src={doc.url} alt="preview" />
            <p>{doc.name.substring(0, 10)}</p>
            <small>Status</small>
            <div className="curation_status">
              <BsCloudCheck className="color-s" />
              <FaBroom className="color-gray" />
              <FaCheck className="color-gray" />
            </div>
          </div>
        ))
      ) : (
        <div className="p-1">
          <FaFrown className="my-1 color-gray" size={"3em"} />
          <h5>
            <BsFillFileEarmarkExcelFill /> No data
          </h5>
          <p className="mt-1">
            You have no activity, please upload a photo to get a curation
            process up and running
          </p>
        </div>
      )}
    </div>
  );
};

export default UserActivities;
