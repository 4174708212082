import { Link } from "react-router-dom";
import "./PaymentCancelPage.css";

const PaymentCancelPage = () => {
  return (
    <div className="text-center my-1 p-1">
      <h2>payment cancelled ❌</h2>
      <p className="my-1">
        You can revisit your profile page and make the payment again 👍
      </p>
      <Link to={"/profile"}>back to profile</Link>
    </div>
  );
};

export default PaymentCancelPage;
