import { FunctionComponent } from "react";
import "./Skeleton.css";

const Skeleton: FunctionComponent<{
  type: string;
  width: string;
  height: string;
  marginTop: string;
}> = ({ type, width, height, marginTop }) => {
  const classes = `skeleton ${type}`;

  return (
    <div className="skeleton_wrapper" style={{ marginTop }}>
      <div className={classes} style={{ width, height }}></div>
    </div>
  );
};

export default Skeleton;
