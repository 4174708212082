import "./Footer.css";

// logo svg
import logo from "../../assets/svg/logo/logo_l.svg";

const Footer = () => {
  return (
    <div className="container">
      <div className="footer">
        <div className="footer_left">
          <img src={logo} alt="logo" />
        </div>
        <div className="footer_right">
          <p>Copyright © 2023</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
