import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@stripe/stripe-js";

// components
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Toast from "./components/Toast/Toast";

// pages
import HomePage from "./pages/HomePage/HomePage";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import AdminPage from "./pages/AdminPage/AdminPage";
import UserActivityDetailsPage from "./pages/UserActivityDetailsPage/UserActivityDetailsPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage/PaymentSuccessPage";
import PaymentCancelPage from "./pages/PaymentCancelPage/PaymentCancelPage";

// admin routes
import AdminRoute from "./routes/AdminRoute";
import PrivateRoute from "./routes/PrivateRoute";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <BrowserRouter>
        <Navbar setIsOpen={setIsOpen} />
        <Toast />
        <Routes>
          <Route
            path="/"
            element={<HomePage isOpen={isOpen} setIsOpen={setIsOpen} />}
          />
          <Route
            path="disabled-signup"
            element={<SignUpPage setIsOpen={setIsOpen} />}
          />
          <Route path="profile" element={<ProfilePage />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="profile/activity-details/:id"
              element={<UserActivityDetailsPage />}
            />
            <Route path="payment-success" element={<PaymentSuccessPage />} />
            <Route path="payment-cancel" element={<PaymentCancelPage />} />
          </Route>

          <Route
            path="admin/dashboard"
            element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            }
          />

          {/* error page */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
