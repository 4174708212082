import { Dispatch, FC, FormEvent, useEffect, useRef } from "react";
import { useState, ChangeEvent } from "react";
import "./SignUpPage.css";
import { Link, useNavigate } from "react-router-dom";
import {
  FaUserPlus,
  FaEye,
  FaEyeSlash,
  FaExclamationCircle,
  FaSpinner,
} from "react-icons/fa";

// validation utils
import {
  isEmail,
  isPasswordCorrect,
  setSuccess,
  setError,
} from "../../utils/validation";

// auth context
import { useAuth } from "../../contexts/AuthContext";
import { UserCredential } from "firebase/auth";

// components
import GoogleAuthBtn from "../../components/GoogleAuthBtn/GoogleAuthBtn";

const SignUpPage: FC<{ setIsOpen: Dispatch<boolean> }> = ({ setIsOpen }) => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  // refs
  const emailInputRef = useRef<HTMLDivElement | null>(null);
  const passwordInputRef = useRef<HTMLDivElement | null>(null);

  const { signUp, currentUser } = useAuth();

  const navigate = useNavigate();

  // useEffect hook
  useEffect(() => {
    if (currentUser) navigate("/");
  }, [navigate, currentUser]);

  // change handler
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputHandler = (e: FormEvent<HTMLInputElement>) => {
    const form_group = e.currentTarget.parentElement;

    form_group?.classList.remove("error");
  };

  // form submit handler
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();

    const { email, password } = values;

    let emailValidated = false;
    let passwordValidated = false;

    // input validation
    if (email === "") {
      setError(emailInputRef, "Email can not be blank");
    } else if (!isEmail(email)) {
      setError(emailInputRef, "Email is invalid");
    } else {
      setSuccess(emailInputRef);
      emailValidated = true;
    }

    if (password === "") {
      setError(passwordInputRef, "Password can not be blank");
    } else if (!isPasswordCorrect(password)) {
      setError(
        passwordInputRef,
        "Minimum eight characters, at least one letter, one number and one special character"
      );
    } else {
      setSuccess(passwordInputRef);
      passwordValidated = true;
    }

    if (emailValidated && passwordValidated) {
      setLoading(true);

      try {
        const res: UserCredential = await signUp(email, password);

        if (res.user) {
          setLoading(false);
          setValues({ email: "", password: "" });
          navigate("/");
        }
      } catch (error: any) {
        setLoading(false);

        if (error.code === "auth/email-already-in-use") {
          setErrorMsg("User already exists, please sign in");
        }
      }
    }
  };

  return (
    <div className="container sign_up">
      <h2 className="d-flex align-item-center gap-10">
        <FaUserPlus /> Sign Up
      </h2>
      <p className="my-1">Please sign up</p>

      <GoogleAuthBtn text="Sign up" />

      <form onSubmit={submitHandler} noValidate>
        <div ref={emailInputRef} className="form_group">
          <label htmlFor="email" className="form_label">
            Email
          </label>
          <input
            className="form_input"
            type="email"
            name="email"
            id="email"
            required
            onChange={changeHandler}
            onInput={onInputHandler}
            value={values.email}
          />

          <small>Some error message</small>
        </div>

        <div ref={passwordInputRef} className="form_group">
          <label htmlFor="password" className="form_label">
            Password
          </label>
          <input
            className="form_input"
            type={viewPassword ? "text" : "password"}
            name="password"
            id="password"
            required
            onChange={changeHandler}
            onInput={onInputHandler}
            value={values.password}
          />

          <button
            className="view_password"
            type="button"
            onClick={() => setViewPassword((prevState) => !prevState)}
          >
            {viewPassword ? (
              <FaEye size={"18px"} />
            ) : (
              <FaEyeSlash size={"18px"} />
            )}
          </button>

          <small>Some error message</small>
        </div>

        {/* error msg */}
        <div>
          {errorMsg && (
            <p className="mt-1 bg-err d-flex align-item-center gap-10">
              <FaExclamationCircle /> {errorMsg}
            </p>
          )}
        </div>

        <button type="submit" className="my-1 btn d-flex">
          {loading ? <FaSpinner /> : "Create an account"}
        </button>
      </form>

      <div className="sign_in_footer">
        <p>
          Have an account?{" "}
          <Link
            to={"/"}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpPage;
