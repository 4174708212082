import "./PaymentSuccessPage.css";
import { Link } from "react-router-dom";

const PaymentSuccessPage = () => {
  return (
    <div className="text-center my-1 p-1">
      <h1>Payment successful 🥳</h1>
      <p className="my-1">Thank you for the purchase 🎉</p>
      <Link to={"/profile"}>Go back to profile</Link>
    </div>
  );
};

export default PaymentSuccessPage;
