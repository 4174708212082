import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { auth, provider } from "../firebase-config";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
  signInWithPopup,
} from "firebase/auth";

interface IAuthContext {
  loading: boolean;
  currentUser: any;
  signUp: any;
  logIn: any;
  logOut: any;
  signInWithGoogle: any;
}

const defaultValue = {
  loading: true,
  currentUser: {},
  signUp: (email: "", password: "") => {},
  logIn: (email: "", password: "") => {},
  logOut: () => {},
  signInWithGoogle: () => {},
};

const AuthContext = createContext<IAuthContext>(defaultValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      console.log("user cred", user);

      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsub();
  }, []);

  const signUp = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password);

  const logIn = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const logOut = () => signOut(auth);

  // sign in with Google
  const signInWithGoogle = () => signInWithPopup(auth, provider);

  const value = {
    loading,
    currentUser,
    signUp,
    logIn,
    logOut,
    signInWithGoogle,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
