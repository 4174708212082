import {
  Dispatch,
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from "react";
import "./UploadImageModal.css";
import { useDropzone } from "react-dropzone";

// firebase services
import { addImageToFirebase } from "../../services/firebase";

// context
import { useAuth } from "../../contexts/AuthContext";

// svg
import uploadSvg from "../../assets/svg/upload.svg";
import deleteSvg from "../../assets/svg/delete.svg";
import crossSvg from "../../assets/svg/cross.svg";

const UploadImageModal: FunctionComponent<{
  setIsOpen: Dispatch<any>;
}> = ({ setIsOpen }) => {
  const [files, setFiles] = useState<(File & { preview: string })[]>([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);

  // auth context
  const { currentUser } = useAuth();

  // callback function - onDrop the image
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  // react-dropzone hooks
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: {
        "image/*": [".jpeg", ".png"],
      },
    });

  // image thumbs
  const thumbs = files.map((file) => (
    <div className="preview_body" key={file.name}>
      <div className="preview_body_left">
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt="preview"
        />
      </div>

      <div className="preview_body_right">
        <button onClick={deleteHandler} disabled={loading}>
          <img id="delete_icon" src={deleteSvg} alt="delete svg" />
        </button>
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  // delete click handler
  function deleteHandler() {
    setFiles([]);
  }

  // upload image handler
  const uploadHandler = async () => {
    setLoading(true);

    try {
      if (files.length === 0) return;
      const res: any = await addImageToFirebase(
        files,
        setPercent,
        currentUser.uid
      );
      // console.log(res);
      if (res) {
        setFiles([]);
        setSuccess(true);
        setLoading(false);

        setTimeout(() => {
          setSuccess(false);
        }, 8000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setIsOpen(false)} />

      <div className="flex-center">
        <div className="modal_container">
          <div className="cross_btn">
            <img
              onClick={() => {
                setIsOpen(false);
              }}
              src={crossSvg}
              alt="cross btn"
            />
          </div>
          <div
            className={`drag_area text-center ${
              isDragReject ? "error-msg " : ""
            } ${isDragAccept ? "active" : ""}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            {isDragReject ? (
              <p>Sorry this app only accept images :(</p>
            ) : isDragAccept ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                <img src={uploadSvg} alt="upload svg" width={100} />
                <p>Drag 'n' drop or click to upload the image for curation</p>

                <p className="bg-warning mt-2">
                  Please upload your baby ultrasound image in png, jpeg, dicom
                  format
                </p>
              </>
            )}
          </div>

          {files.length === 1 && (
            <>
              <aside className="preview">{thumbs}</aside>

              <button
                className="btn btn-green"
                onClick={uploadHandler}
                disabled={loading}
              >
                {loading ? `uploading... ${percent}%` : "Upload image"}
              </button>
            </>
          )}

          {/* success message */}
          {success && (
            <p className="s-msg text-center">
              Image has been successfully uploaded
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadImageModal;
