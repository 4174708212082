import { Navigate, Outlet } from "react-router-dom";

// autj
import { useAuth } from "../contexts/AuthContext";

const PrivateRoute = () => {
  const { currentUser, loading } = useAuth();
  return !currentUser && !loading ? <Navigate to={"/"} /> : <Outlet />;
};

export default PrivateRoute;
