import { FC, useEffect } from "react";
import "./ProfilePage.css";
import { useNavigate } from "react-router-dom";
import { HiOutlineMail, HiOutlineCheckCircle } from "react-icons/hi";
import { RiErrorWarningLine } from "react-icons/ri";

// auth context
import { useAuth } from "../../contexts/AuthContext";
import { User } from "firebase/auth";

// components
import Avatar from "../../components/Avatar/Avatar";
import Skeleton from "../../components/Skeleton/Skeleton";
import UserActivities from "../../components/UserActivities/UserActivities";

// svg
import activitySvg from "../../assets/svg/activity.svg";

const ProfilePage: FC = () => {
  const { currentUser, loading } = useAuth();

  let user: User = currentUser;

  const navigate = useNavigate();
  // useEffect hook
  useEffect(() => {
    if (!currentUser && !loading) {
      navigate("/");
    }
  }, [navigate, currentUser, loading]);

  const profileInfo = () => {
    if (user) {
      const { displayName, email, emailVerified } = user;

      return (
        <div className="my-1 p-1 profile_info">
          <div className="info_left">
            <Avatar type="xl" />
          </div>

          <div className="info_right p-1">
            <h2>{displayName}</h2>
            <p className="my-1 d-flex gap-10">
              <HiOutlineMail size={"20px"} /> {email}
            </p>
            <p className="d-flex gap-10">
              Email Verified :{" "}
              {emailVerified ? (
                <HiOutlineCheckCircle size={"1.3rem"} className="color-s" />
              ) : (
                <RiErrorWarningLine size={"1.3rem"} className="color-w" />
              )}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="my-1 p-1 profile_info">
          <div>
            <Skeleton
              type="circle"
              width="150px"
              height="150px"
              marginTop="0"
            />
          </div>

          <div className="p-1 w-100">
            <Skeleton type="" width="100%" height="10px" marginTop="16px" />
            <Skeleton type="" width="100%" height="10px" marginTop="16px" />
            <Skeleton type="" width="100%" height="10px" marginTop="16px" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container">
      {profileInfo()}

      <div className="activities my-1 p-1">
        <div className="d-flex my-1">
          <img src={activitySvg} alt="activity" />
          <h2 className="px-1">Activities</h2>
        </div>

        <UserActivities />
      </div>
    </div>
  );
};

export default ProfilePage;
