import { Dispatch, FC } from "react";
import "./AuthModal.css";

//svg
import crossSvg from "../../assets/svg/cross.svg";

// components
import SignIn from "../SignIn/SignIn";

const AuthModal: FC<{ setIsOpen: Dispatch<boolean> }> = ({ setIsOpen }) => {
  return (
    <div>
      <div className="overlay" onClick={() => setIsOpen(false)} />

      <div className="flex-center">
        <div className="modal_container">
          <div className="cross_btn">
            <img
              onClick={() => {
                setIsOpen(false);
              }}
              src={crossSvg}
              alt="cross btn"
            />
          </div>

          {/* Auth modal content */}

          <SignIn />
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
