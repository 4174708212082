import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  serverTimestamp,
  query,
  where,
  orderBy,
} from "firebase/firestore";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Dispatch, SetStateAction } from "react";

// import db from firebase config file
import { db, storage } from "../firebase-config";

// upload image to firebase storage
export const addImageToFirebase = (
  images: File[],
  setPercent: Dispatch<SetStateAction<number>>,
  uid: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // image upload to firebase storage
      images.forEach((file: File) => {
        const storageRef = ref(storage, `image/${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                setPercent(progress);
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
          },
          async () => {
            // Handle successful uploads on complete
            // For instance, get the download URL
            try {
              const URL = await getDownloadURL(uploadTask.snapshot.ref);

              // collection ref
              const colRef = collection(db, "receivedImages");
              const imgInfo = {
                url: URL,
                name: file.name,
                type: file.type,
                createdBy: uid,
                createdAt: serverTimestamp(),
              };
              const { id } = await addDoc(colRef, imgInfo);

              // console.log("images upload success add to DB done");
              resolve(id);
            } catch (e) {
              console.log(e);
            }
          }
        );
      });
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

// get a doc
export const getDocById = async (collectionName: string, id: string) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collectionName, id);

    try {
      onSnapshot(docRef, (doc) => {
        resolve({ ...doc.data(), id: doc.id });
      });
    } catch (error) {
      reject(error);
    }
  });
};

// get data by user id
export const getDataByUID = (collectionName: string, uid: string) => {
  return new Promise((resolve, reject) => {
    const colRef = collection(db, collectionName);
    const q = query(colRef, where("createdBy", "==", uid));

    try {
      onSnapshot(q, (docs) => {
        let data: {}[] = [];

        docs.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });

        resolve(data);
      });
    } catch (error) {
      reject(error);
    }
  });
};

// get all docs
export const getAllDocs = (collectionName: string) => {
  return new Promise((resolve, reject) => {
    const colRef = collection(db, collectionName);
    const q = query(colRef, orderBy("createdAt", "desc"));

    try {
      onSnapshot(q, (docs) => {
        let data: {}[] = [];

        docs.forEach((doc) => {
          return data.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        resolve(data);
      });
    } catch (error) {
      reject(error);
    }
  });
};
