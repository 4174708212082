import { Dispatch, FC } from "react";
import "./Navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSignInAlt, FaUserPlus, FaHome } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { GoDashboard } from "react-icons/go";

// context
import { useAuth } from "../../contexts/AuthContext";

// components
import Avatar from "../Avatar/Avatar";
import Skeleton from "../Skeleton/Skeleton";

// utils
import { toggleToastMsg } from "../../utils/validation";

const Navbar: FC<{ setIsOpen: Dispatch<boolean> }> = ({ setIsOpen }) => {
  const { loading, currentUser, logOut } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // burger click handler
  const burgerHandleClick = () => {
    document.querySelector(".burger")?.classList.toggle("open");
    document.querySelector("nav")?.classList.toggle("open");
    document.querySelector("#nav_overlay")?.classList.toggle("overlay");
  };

  // log out btn handler
  const logOutHandler = () => {
    logOut();
    toggleToastMsg("success", "Successfully logged out");
  };

  const sideNav = () => (
    <div>
      <ul className="nav_links">
        <li
          onClick={() => {
            navigate("/");
            burgerHandleClick();
            setIsOpen(true);
          }}
          className="d-flex align-item-center gap-10"
        >
          <FaSignInAlt /> Sign in
        </li>
        {/* <li
          onClick={() => {
            burgerHandleClick();
            navigate("/signup");
          }}
          className="d-flex align-item-center gap-10"
        >
          <FaUserPlus /> Sign up
        </li> */}

        {pathname !== "/" && (
          <li
            onClick={() => {
              burgerHandleClick();
              navigate("/");
            }}
            className="d-flex align-item-center gap-10"
          >
            <FaHome /> Home
          </li>
        )}
      </ul>
    </div>
  );

  return (
    <>
      <div id="nav_overlay" onClick={burgerHandleClick} />
      <nav>
        <div>
          {currentUser ? (
            <div className="nav_links">
              <div
                onClick={() => {
                  burgerHandleClick();
                }}
              >
                <Avatar /> {currentUser.email.split("@")[0]}
              </div>
              <button
                className="mt-1 btn d-flex align-item-center"
                onClick={logOutHandler}
              >
                <TbLogout /> log out
              </button>

              {pathname !== "/" && (
                <li
                  onClick={() => {
                    burgerHandleClick();
                    navigate("/");
                  }}
                  className="d-flex align-item-center gap-10"
                >
                  <FaHome /> Home
                </li>
              )}

              {currentUser.email === "dad@md.com" &&
                pathname !== "/admin/dashboard" && (
                  <li
                    onClick={() => {
                      burgerHandleClick();
                      navigate("/admin/dashboard");
                    }}
                    className="d-flex align-item-center gap-10"
                  >
                    <GoDashboard /> Admin dashboard
                  </li>
                )}
            </div>
          ) : loading ? (
            <>
              <Skeleton type="" width="100%" height="30px" marginTop="0" />
              <Skeleton type="" width="100%" height="10px" marginTop="10px" />
              <Skeleton type="" width="100%" height="10px" marginTop="10px" />
            </>
          ) : (
            sideNav()
          )}
        </div>
      </nav>

      <div className="d-flex-end container px-1">
        <Avatar />

        <div className="burger" onClick={burgerHandleClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default Navbar;
