import { ChangeEvent, FormEvent, FunctionComponent, useState } from "react";
import "./SignIn.css";
import { Link } from "react-router-dom";
import { FaUsers, FaSpinner, FaEye, FaEyeSlash } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";

// context
import { useAuth } from "../../contexts/AuthContext";
import { User } from "firebase/auth";

// components
import GoogleAuthBtn from "../GoogleAuthBtn/GoogleAuthBtn";

// utils
import { toggleToastMsg } from "../../utils/validation";

const SignIn: FunctionComponent = () => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  // auth context
  const { logIn } = useAuth();

  // change handler
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    setErrorMsg("");
  };

  // on submit
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // console.log(values);
    const { email, password } = values;
    try {
      const res: User = await logIn(email, password);
      // console.log(res);
      if (res) {
        toggleToastMsg("success", "Successfully logged in");
      }
    } catch (error: any) {
      setLoading(false);

      if (error.code === "auth/wrong-password") {
        setErrorMsg(
          "User name or password is incorrect. Make sure the credentials are correct"
        );
      }
      if (error.code === "auth/user-not-found") {
        setErrorMsg(
          "User name or password is incorrect. Make sure the credentials are correct"
        );
      }

      if (error.code === "auth/too-many-requests") {
        setErrorMsg(
          "Access to this account has been temporarily disabled due to many failed login attempts."
        );
      }
    }
  };

  return (
    <div className="signin">
      <h2 className="d-flex align-item-center gap-10">
        <FaUsers size={"1.5em"} /> Sign In
      </h2>
      <p className="my-1">Please sign in first to upload a image</p>

      {/* <GoogleAuthBtn text="Sign in" /> */}

      <form onSubmit={submitHandler}>
        <div>
          <label htmlFor="email" className="form_label">
            Email
          </label>
          <input
            className="form_input"
            type="email"
            name="email"
            id="email"
            required
            onChange={changeHandler}
          />
        </div>

        <div className="form_group">
          <label htmlFor="password" className="form_label">
            Password
          </label>
          <input
            className="form_input"
            type={viewPassword ? "text" : "password"}
            name="password"
            id="password"
            required
            onChange={changeHandler}
          />

          <button
            className="view_password"
            type="button"
            onClick={() => setViewPassword((prevState) => !prevState)}
          >
            {viewPassword ? (
              <FaEye size={"18px"} />
            ) : (
              <FaEyeSlash size={"18px"} />
            )}
          </button>
        </div>

        {/* error msg */}
        <div>
          {errorMsg && (
            <p className="mt-1 bg-err d-flex align-item-center gap-10">
              <BsExclamationCircle /> {errorMsg}
            </p>
          )}
        </div>

        <button type="submit" className="my-1 btn d-flex">
          {loading ? <FaSpinner /> : "Sign in"}
        </button>
      </form>

      <div className="sign_in_footer">
        {/* <p>
          Don't have an account? <Link to={"/signup"}>Sign up</Link>
        </p> */}
      </div>
    </div>
  );
};

export default SignIn;
