import { useEffect, useState } from "react";
import "./UserActivityDetailsPage.css";
import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { FaArrowRight } from "react-icons/fa";
import { BsCloudArrowDownFill } from "react-icons/bs";

// stripe
import { loadStripe, Stripe } from "@stripe/stripe-js";

// firebase services
import { getDocById } from "../../services/firebase";

interface ReceivedImages {
  id: string;
  name: string;
  type: string;
  url: string;
  createdBy: string;
  createdAt: Timestamp;
}

// stripe set up
let stripePromise: Promise<Stripe | null>;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
  }

  return stripePromise;
};

const UserActivityDetailsPage = () => {
  const [data, setData] = useState<ReceivedImages | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const abortController = new AbortController();

    if (id) {
      getDocById("receivedImages", id)
        .then((res: any) => {
          console.log(res);
          setError("");
          setIsLoading(false);
          setData(res);
        })
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    }

    return () => abortController.abort();
  }, [id]);

  // stripe
  const checkoutOptions: any = {
    lineItems: [{ price: "price_1M9Xc2DBvQdxUklDIHxqQfje", quantity: 1 }],
    mode: "payment",
    successUrl: `${window.location.origin}/payment-success`,
    cancelUrl: `${window.location.origin}/payment-cancel`,
  };

  const redirectToCheckout = async () => {
    console.log("checkout runs!!");

    const stripe = await getStripe();
    await stripe?.redirectToCheckout(checkoutOptions);
  };

  if (error) return <div className="text-center">fetch error</div>;

  if (isLoading) return <div className="text-center">loading...</div>;

  return (
    <main>
      <section className="container p-1">
        <div className="activity_details">
          {data && (
            <div>
              <h3 className="my-1">Before</h3>
              <img src={data.url} alt="preview" width={200} />
              <p className="my-1">{data.name}</p>
              <p>{data.createdAt.toDate().toString().substring(0, 16)}</p>
            </div>
          )}

          <div className="d-flex align-item-center">
            <FaArrowRight size={"2em"} />
          </div>

          {data && (
            <div>
              <h3 className="my-1">After</h3>
              <img src={data.url} alt="preview" width={200} />
            </div>
          )}

          <div>
            <button
              className="btn d-flex align-item-center gap-10"
              onClick={redirectToCheckout}
            >
              <BsCloudArrowDownFill size={"1.7em"} /> $1.00 Download
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default UserActivityDetailsPage;
