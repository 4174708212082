import { FC } from "react";
import "./Toast.css";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const Toast: FC = () => (
  <div className="toast align-item-center">
    <div className="toast_icon">
      <FaCheckCircle className="fa-solid fa-circle-check" />
      <FaExclamationCircle className="fa-solid fa-circle-exclamation" />
    </div>
    <div className="toast_msg"></div>
  </div>
);

export default Toast;
